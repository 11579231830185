export default {
  admin_noDataForSelectionParametars: 'Nu există date pentru',
  betslip_betslip: 'talon de pariuri',
  betslip_maxBetAmount: 'Suma max. a pariului este',
  betslip_maxLengthIs: 'Lungimea max. este',
  betslip_maxWinLimit: 'Limită max. de câștig',
  betslip_minLengthIs: 'Lungimea min. este',
  betslip_stake: 'miză',
  betslip_startBettingMessage: 'Selectați rezultatul corespunzător pentru a începe parierea',
  betslip_ticketResolving: 'soluționare bilet',
  betslip_totalCombinations: 'total combinații',
  betslip_totalStake: 'miză totală',
  betslip_ticketAccepted: 'bilete acceptate',
  betslip_balanceWarning: 'Credit insuficient',
  betslip_maxTicketAmount: 'Sumă max. bilet',
  betslip_minBetAmount: 'Sumă min. de pariere',
  betslip_minTicketAmount: 'Sumă min. a biletului',
  betslip_stakePerBet: 'Miză pe pariu',
  betslip_toReturn: 'Câștig pos.',
  betslip_invalidDrawType: 'Tip de extragere invalid',
  betslip_invalidOutcomeNumbers: 'Numere jucate invalide pentru tipul de rezultat selectat',
  betslip_invalidStandardBet: 'Pariul standard nu este valabil',
  betslip_missingRequestUuid: 'Cerere uuid lipsă',
  betslip_numbersRange: 'Numerele jucate trebuie să fie în intervalul turneului',
  betslip_sameDrumBonusBall: 'Bila bonus pentru aceeași urnă nu poate fi trimisă',
  betslip_haveOnBetslip: 'Este deja pe talonul de pariuri.',
  betslip_maxBetCountIs: 'Numărul max. de pariuri este',
  betslip_oddsHaveChangedFrom: 'Odds have change from',
  betslip_acceptAndPayin: 'Accept and Pay in',
  betslip_oddsHaveChanged: 'Cotele s-au schimbat',
  betslip_outcomeIsAlready: 'Outcome is already added to the betslip',
  game_NumbersBetting: 'Loterie',
  general_accept: 'acceptă',
  general_all: 'toate',
  general_bonus: 'bonus',
  general_cancel: 'anulează',
  general_check: 'verifică',
  general_close: 'închide',
  general_combo: 'combinat',
  general_country: 'țară',
  general_currentlyUnavailable: 'Parierea pe acest eveniment nu este disponibilă momentan.',
  general_deleteShort: 'șterge',
  general_error: 'eroare',
  general_event: 'eveniment',
  general_invalidTicketId: 'Cod bilet invalid',
  general_lastTickets: 'ultimele bilete',
  general_lost: 'pierdute',
  general_market: 'piață',
  general_maxWinShort: 'câștig max.',
  general_number: 'număr',
  general_numbers: 'numerele',
  general_odd: 'impare',
  general_open: 'deschide',
  general_payIn: 'depunere',
  general_payinTax: 'taxă pe depunere',
  general_payout: 'plată',
  general_payoutAmount: 'sumă plătită',
  general_payoutTax: 'taxă pe plată',
  general_pick: 'alege',
  general_possiblePayoutTax: 'taxă posibilă pentru plată',
  general_possibleWin: 'câștig posibil',
  general_requiredField: 'câmp obligatoriu',
  general_results: 'rezultate',
  general_search: 'căutare',
  general_selection: 'selecție',
  general_signIn: 'autentificați-vă',
  general_single: 'individual',
  general_ticket: 'bilet',
  general_ticketCheck: 'verificare bilet',
  general_time: 'timp',
  general_totalOdds: 'total cote',
  general_winnings: 'câștiguri',
  general_won: 'câștigate',
  general_pleaseWait: 'vă rugăm, așteptați',
  general_today: 'astăzi',
  general_clear: 'șterge',
  general_edit: 'editează',
  general_reset: 'restabilește',
  general_save: 'salvează',
  general_selectAll: 'selectează tot',
  general_totalTickets: 'total bilete',
  general_add: 'adaugă',
  general_ticketCode: 'cod bilet',
  general_updateBet: 'actualizează pariul',
  general_payedout: 'plată',
  general_random: 'aleatoriu',
  general_addBet: 'adaugă pariu',
  general_more: 'mai multe',
  general_info: 'informații',
  general_successs: 'reușită',
  general_categories: 'categorii',
  general_others: 'altele',
  general_popular: 'popular',
  general_success: 'reușită',
  general_ticketId: 'Biletul ID',
  general_all_countries: 'toate țările',
  general_popular_lottery: 'loterie populară',
  general_tournamentCancelled: 'turneu anulat',
  general_in_play: 'live',
  general_warning: 'avertisment',
  general_clearAll: 'Curata tot',
  general_refresh: 'reîmprospăta',
  general_sortByCategories: 'Sortați după categorii',
  general_sortByTime: 'Sortați după timp',
  general_translations: 'Traduceri',
  general_betslip: 'Talon de pariuri',
  general_bonus_balls: 'Bile bonus',
  general_pay_in: 'Plătiți',
  general_stake_per_bet: 'Miza pe pariu',
  general_back: 'Retur',
  general_bet: 'Pariu',
  general_to: 'la',
  general_betID: 'ID-ul pariului',
  general_eventID: 'ID eveniment',
  general_outcomeID: 'ID decontării',
  general_match_locked: 'Meci blocat',
  general_name: 'Nume',
  general_offer: 'Oferta',
  general_selectAnOption: 'Selectați opțiunea',
  general_selectionContent: 'Selectați conținutul',
  general_type: 'Tip',
  general_noDataAvailable: 'Nu există date pentru',
  general_oddsMgmt: 'Impare management',
  general_quickPick: 'Alegere rapida',
  general_internalError: 'Eroare internă',
  general_status: 'Statusul',
  general_id: 'ID',
  general_deleteLockedBets: 'Delete locked bets?',
  general_acceptAndProceed: 'Accept and Proceed',
  general_min_payment_per_ticket_rule: 'Minimum payment per ticket is {value}.',
  general_max_payment_per_ticket_rule: 'Maximum payment per ticket is {value}.',
  general_max_bet_amount_rule: 'Maximum bet amount is {value}.',
  general_min_bet_amount_rule: 'Minimum bet amount is {value}.',
  general_noInternet: 'It appears you are offline. Please check your internet connection.',
  general_application: 'Aplicație',
  general_channel: 'Canal',
  general_category: 'Categorie',
  general_tournament: 'Turneu',
  general_player: 'Jucător',
  general_operator: 'Operator',
  general_betshop: 'Casa de pariuri',
  general_hour: 'Ora',
  general_week: 'Săptămână',
  general_month: 'Lună',
  general_maxPaymentAmount: 'Suma maximă de plată',
  general_maxTicketCount: 'Numărul maxim de bilete',
  general_maxSameNumbersSelection: 'Pariuri maxime cu aceleași numere',
  general_futureBets: 'Future bets',
  general_future_bet: 'Future bet',
  general_stake_with_future_bets: 'Stake with all future bets',
  nb_bonusBall: 'Bile bonus',
  shop_betIsNotFound: 'Pariul nu a fost găsit',
  shop_outcomeIsNotInOffer: 'Decontării nu este oferit.Match with ID',
  shop_matchWithId: 'Match with ID',
  shop_notfoundAlreadyStarted: 'Nu a fost găsit sau a început deja',
  shop_allEventsStarted: 'All events have already started',
  shop_webCodeInvalidOrExpired: 'Web code is invalid or has expired',
  shop_nextEventStartingIn: 'Următorul eveniment începe în',
  shop_newTicket: ' Biletul',
  shop_print: ' Listarea ofertei',
  shop_results: 'Listarea rezultatelor',
  shop_random: 'Aleatoriu',
  shop_fullOffer: 'Oferta completă',
  shop_resetTicket: 'Resetează biletul',
  shop_ticketType: 'Tipul biletului',
  shop_offerResultsPrint: 'Offer/results Print',
  shop_printPreview: 'Print preview',
  shop_offerSelect: 'Offer print selection',
  shop_resultsSelect: 'Results print selection',
  shop_bettingDisabled: 'Betting disabled',
  shop_printOffer: 'Print Offer',
  shop_resultsOffer: 'Results Offer',
  shop_removeDuplicateNumbers: 'Remove duplicate numbers',
  shop_printPreviewIsGenerating: 'Please wait. Print preview is generating',
  shop_sameLocationPayoutRestriction: 'Ticket can be payed out only from bet shop where played',
  shop_unknownTicketCheck: 'Unknown ticket',
  shop_addToBetslip: 'Add to betslip',
  general_system: 'Sistem',
  shop_removeBallsSelection: 'Remove balls selection',
};
